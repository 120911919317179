import Api from '@api';
import { getFormConfig, noFilterGroup } from "./configs/formConfig";
import { getTableColumn } from './configs/tableConfig';
import { getSearchValueList, keyToConfigList, uniqueArr, isCanShowBlock } from '@utils';
import { TABLE_COLUMN_COMMON } from '@constant';
import habitMixins from '@/mixins/habit.js';
import formTableInitMixins from '@/mixins/formTableInit.js'
import exportEdit from '@/mixins/exportEdit.js';

export default {
  name: 'invHistory',
  mixins: [habitMixins,formTableInitMixins, exportEdit],
  provide() {
    return {
      getTableList: this.getList
    };
  },
  data: function () {
    const dataTableName = 'invHistory';
    const formConfig = getFormConfig(this).filterFormConfig.filter(item => (item.label && isCanShowBlock('searchInfo', item.showBlock)));
    const tableColumn = getTableColumn(this).tableColumn;

    const ALL_SEARCH_ITEM_LIST = JSON.parse(window.sessionStorage.getItem('ALL_SEARCH_ITEM_LIST'))[dataTableName];
    const ALL_TABLE_COLUMNS = JSON.parse(window.sessionStorage.getItem('ALL_TABLE_COLUMNS'))[dataTableName];

    const filterFormConfig = keyToConfigList(ALL_SEARCH_ITEM_LIST, formConfig);
    const tableColumnConfigList = keyToConfigList(ALL_TABLE_COLUMNS, tableColumn, 'tag');

    return {
      formData: {}, //筛选条件
      importData: [], // table 数据
      filterFormConfig,
      conditions: formConfig, // 增加查询条件的值
      tableColumn, // 原本的表格配置
      tableColumnConfigList,
      dataTableName,
      dialog: null,
      injectData: {},
      initFormData:{},
      searchItemList: ALL_SEARCH_ITEM_LIST,
      tableColumnList: ALL_TABLE_COLUMNS,
      selectionList: [],
      rangeSelectionList: [],
    }
  },
  mounted(){
    // this.getList()
  },
  methods: {
    /**
     * @description 初始化数据
     */
    async getList() {
      let CRITERION_LIST = getSearchValueList(this.conditions, this.$refs.filterForm.formData)
      let SHOW_COLUMN_LIST = this.tableColumnConfigList.map(item => item.tag).filter(item => !TABLE_COLUMN_COMMON.includes(item))
      let EXTRA_COLUMN = ['QTY_ON_HAND']
      let params = {
        CRITERION_LIST: CRITERION_LIST,
        SHOW_COLUMN_LIST: uniqueArr([...SHOW_COLUMN_LIST,...EXTRA_COLUMN]),
        PAGE_NUM: this.$refs.configTable.page.currentPage,
        PAGE_SIZE: this.$refs.configTable.page.pageSize
      };
      this.exportParams = params;
      this.initFormData = this.$refs.filterForm.formData;
      const { status, data, message } = await Api.GetCInventoryTotalList(params);
      if(status === 200){
        this.importData = data.list
        this.$refs.configTable.page.total = Number(data.total)
      }
    },
    /**
     * @description 表单编辑时候的跳转
    */
    clientEdit(params, type) {
      this.injectData = {...params};
      this.$router.push({path:'/invHistoryDetail',query:this.injectData})
    },
    /**
     * @description 清除表单和父子间的数据
    */
    dialogClosed(){
      this.dialog = null;
      this.injectData = {};
    },
    /**
     * @description 双击行打开详情
     */
    handleDBClick(row) {
     // this.clientEdit(row, 'query')
    },
    /**
     * @description 导出
     */
    handleExport() {
      this.exportDefine('库存汇总查询表', 'ExportTotlCextension')
    },
  }
}